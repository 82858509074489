import '../styles/Footer.css'
import Logo from "../assets/Banner-for-Website-Content.png"
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import Content from "./Content";
import {Link} from "react-router-dom";


const About = () => {
    return (
        <div>
            <TopMenu back_image={Logo}/>
            <div className='footer-title'> <span>ABOUT US</span></div>
            <Content>
                <div className='about-container'>
                    <h2>MESSION</h2>
                    <p>
                        If actions speak louder than words, do actions AND words speak the loudest of them all? We think so. <br/>
                        That’s why lifetostyle.net always promises both. From scouring the country for top-rated reviews, trusted research, and the best deals to shouting this information from the “rooftops” (okay, just from our website), we let actions and words do the talking.
                        What do we mean by that? We mean lifetostyle.net puts in the work to find the most specialized and trustworthy sources for the information you need. We then vet and gather information, then share it in a digestible manner.
                        So whether you are coping with chronic illness or choosing which hybrid car to purchase, know that we wake up each morning committed to assisting you in your decision-making process.<br/>
                        Welcome to Lifestyle.
                    </p>
                    <h2>OUR CONTENT</h2>
                    <p>
                        The articles you’ll find on lifetostyle.net are authoritative and comprehensive. Our topic experts research and write up content to help you make the best decisions for your life, whether about your finances, health, home, work, and more. <br/>
                        <br/>
                        We want every lifetostyle.net reader to achieve their desired results, so we strive to provide the encouragement and resources needed along the way. And as a company, we want to be seeing results too. That’s why each member of our editorial staff brings
                        special knowledge in their respective realms — from auto to senior living. Beyond that, our seasoned leadership team comes from many years in the digital media space. And across the board, everyone behind lifetostyle.net
                        is committed to keeping up with the most recent trends and discoveries, so you can count on us to provide excellent, reliable content day in and day out.
                    </p>
                    <h2>Contact Us</h2>
                    <p>
                        We love hearing from you! Have questions? Please ask them! Concerns? We learn and grow through your feedback. Do you just want to tell us how awesome we are? Naturally, we’ll accept those messages as well.
                        For all inquiries, please shoot us a message
                        <Link to='/contact' className='link'> here </Link>
                    </p>
                </div>
            </Content>
            <Footer/>
        </div>
    );
}

export default About